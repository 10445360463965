export default {
  methods: {
    mixPermissao(componente, campo) {
      // console.log("!", componente, campo);
      // console.log("@", this.$store.state.login.staPermissoes.permissoes);
      if (this.$store.state.login.staPermissoes.permissoes == undefined) {
        return;
      }
      if (
        this.$store.state.login.staPermissoes.permissoes.some(
          (perm) => perm.componente == componente && perm.campo == campo
        )
      ) {
        // console.log("#", componente, campo, "Verdadeiro");

        return true;
      } else {
        // console.log("#", componente, campo, "Falso");
        return false;
      }
    },
  },
};
