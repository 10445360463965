<template>
  <v-container fluid grid-list-md>
    <v-layout justify-center>
      <v-flex xs6 sm6 md6 lg6 xl6>
        <v-toolbar flat dense
          ><v-toolbar-title style="font-size: 1.8em">
            Administrador
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap align-center>
          <v-flex v-for="(item, i) in items" :key="i">
            <v-card
              :style="'border: 1px solid LightGrey'"
              :to="item.to"
              max-width="300"
              max-height="100"
              hover
              v-if="mixPermissao($options.name, item.name)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="subheading grey--text text--darken-1"
                    v-text="item.subtitle"
                  ></v-card-subtitle>
                </div>

                <v-icon class="mr-4" x-large :color="item.icolor">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "Administrador",
  mixins: [PermissoesMixin],
  data: () => ({
    items: [
      {
        to: "/AdminUsuariosLista",
        icon: "mdi-account-outline",
        icolor: "green darken-2",
        title: "Usuários",
        subtitle: "Usuários do sistema",
        name: "usuarios",
      },
      {
        to: "/adminGruposLista",
        icon: "mdi-account-multiple-outline",
        icolor: "red darken-2",
        title: "Grupos *",
        subtitle: "Grupos de usuários de sistema",
        name: "grupos",
      },

      {
        to: "/adminComponentesLista",
        icon: "mdi-toy-brick-outline",
        icolor: "yellow darken-3",
        title: "Componentes *",
        subtitle: "Componentes do sistema",
        name: "componentes",
      },
      {
        to: "/adminModulosLista",
        icon: "mdi-view-module-outline",
        icolor: "blue darken-2",
        title: "Módulos *",
        subtitle: "Grupo de componentes",
        name: "modulos",
      },
      {
        to: "/adminPerfisLista",
        icon: "mdi-account-circle-outline",
        icolor: "blue darken-2",
        title: "Perfis *",
        subtitle: "Perfis de cada Licença",
        name: "perfis",
      },
      {
        to: "/adminLicencasLista",
        icon: "mdi-license",
        icolor: "indigo darken-2",
        title: "Licenças *",
        subtitle: "Acesso a um Perfil",
        name: "licencas",
      },
    ],
  }),
};
</script>
